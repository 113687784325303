"use strict";

angular.module("informaApp")
    .service("DownloadService", ["$http", "BaseApiService", "ConstantsSvc", "FileDownloader", 'OmnitureSvc', function ($http, BaseApiService, ConstantsSvc, FileDownloader, OmnitureSvc) {
        var tableTypes = {
            all: 'all',
            diseases: 'diseases',
            subdiseases: 'subdiseases',
            companies: 'companies',
            indications: 'indications',
            drugclasses: 'drugclasses',
            qls: 'qls',

            molecule: 'molecules',
            target: 'targets',
            drug: 'drugs',
            route: 'routes'
        };

        function exportFile(path, params, filename, callback) {
            var httpRequest = {
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            };

            const cookies = document.cookie.split('; ');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i];
                if (cookie.indexOf('token=') === 0) {
                    httpRequest.headers = {
                    ...httpRequest.headers,
                    Authorization: `Bearer ${cookie.split('=')[1]}`
                    };
                    break;
                }
            }

            var exportReq = $http(_.merge({
                    url: ConstantsSvc.API.URL + path,
                    method: 'POST',
                    data: params,
                }, httpRequest));

            OmnitureSvc.export.trackMain(filename);
            FileDownloader.download(exportReq, filename, callback);
        }

        function exportTable(params, table, excelFileName, callback) {
            exportFile('table-export/' + table, params, excelFileName, callback);
        }

        function downloadDatasetFile(fileName, callback) {
            exportFile('dataset/download', {fileName: fileName}, 'PremiaData.xlsx', function (result) {
                if (result) {
                    callback(result)
                } else {
                    downloadDatasetFile(fileName, callback);
                }
            });
        }

        return {
            downloadAll: function (params, callback) {
                exportTable(params, tableTypes.all, 'PremiaExport.xlsx', callback);
            },
            downloadDiseases: function (params, callback) {
                exportTable(params, tableTypes.diseases, 'PremiaExport.xlsx', callback);
            },
            downloadSubDiseases: function (params, callback) {
                exportTable(params, tableTypes.subdiseases, 'PremiaExport.xlsx', callback);
            },
            downloadCompanies: function (params, callback) {
                exportTable(params, tableTypes.companies, 'PremiaExport.xlsx', callback);
            },
            downloadIndications: function (params, callback) {
                exportTable(params, tableTypes.indications, 'PremiaExport.xlsx', callback);
            },
            downloadDrugClasses: function (params, callback) {
                exportTable(params, tableTypes.drugclasses, 'PremiaExport.xlsx', callback);
            },
            downloadMolecule: function (params, callback) {
                exportTable(params, tableTypes.molecule, 'PremiaExport.xlsx', callback);
            },
            downloadTarget: function (params, callback) {
                exportTable(params, tableTypes.target, 'PremiaExport.xlsx', callback);
            },
            downloadDrug: function (params, callback) {
                exportTable(params, tableTypes.drug, 'PremiaExport.xlsx', callback);
            },
            downloadQLSDrugDetail: function (params, callback) {
                exportTable(params, tableTypes.qls, 'Premia-QLSDrugDetail-Export.xlsx', callback);
            },
            downloadRoute: function (params, callback) {
                exportTable(params, tableTypes.route, 'PremiaExport.xlsx', callback);
            },
            downloadDataset: function (params, callback) {
                return BaseApiService.post('dataset/generate', params)
                .then(function (response) {
                    var fileName = response.data.fileName;
                    downloadDatasetFile(fileName, callback);
                }).catch(function (e) {
                    console.log(`downloadDataset - catch:error `, e, params, callback);
                    if (callback) {
                        callback(false, e);
                    }
                });
            }
        };
    }]);